<template>
	<v-card>
		<v-card-title>
			<v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
			<v-spacer></v-spacer>
			<v-btn :color="primaryColor" dark style="margin-right: 10px" @click="getList()">
				<i :title="$t('common.update')" class="fas fa-sync" />
			</v-btn>
			<v-btn :color="primaryColor" dark style="float: right" @click="showDeleteAllNotifications()">
				<span class="font-class">
					{{ $t('agentsNotifications.deleteAllNotifications') }}
				</span>
			</v-btn>
		</v-card-title>
		<!-- DATATABLE -->
		<v-data-table :headers="headers" :items="list" :search="search">
			<template v-slot:item.type="{ item }">
				<div v-if="item.type === 'SUCCESS'" class="successNotification">
					{{ item.type }}
				</div>
				<div v-if="item.type === 'ERROR'" class="errorNotification">
					{{ item.type }}
				</div>
				<div v-if="item.type === 'WARNING'" class="warningNotification">
					{{ item.type }}
				</div>
			</template>

			<template v-slot:item.createdAt="{ item }">
				<span>{{ formatDate(item.createdAt) }}</span>
			</template>

			<template v-slot:item.actions="{ item }">
				<v-btn icon v-if="item.register !== ''" :title="$t('agentsNotifications.messageSended')" @click="showMessageSended(item)">
					<i class="fas fa-edit" />
				</v-btn>
				<v-btn icon :title="$t('agentsNotifications.deleteNotification')" @click="showDeleteNotificationDialog(item)">
					<i class="fa fa-trash" />
				</v-btn>
			</template>
		</v-data-table>
		<!-- MODAL DIALOG FOR DELETING A NOTIFICATION -->
		<v-dialog persistent v-model="deleteNotificationDialog" max-width="35%">
			<v-card>
				<v-card-title class="text-h5">
					{{ $t('agentsNotifications.deleteNotificationDialogQuestion') }}
					<v-spacer></v-spacer>
					<v-btn :color="primaryColor" icon @click="hideDeleteNotificationDialog()">
						<i class="fa fa-window-close" />
					</v-btn>
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" @click="deleteNotification()">{{ $t('common.ok') }}</v-btn>
					<v-btn color="error" @click="hideDeleteNotificationDialog()">{{ $t('common.cancel') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- MODAL DIALOG FOR DELETING All NOTIFICATIONs -->
		<v-dialog persistent v-model="deleteAllNotificationDialog" max-width="35%">
			<v-card>
				<v-card-title class="text-h5">
					{{ $t('agentsNotifications.deleteAllNotificationDialogQuestion') }}
					<v-spacer></v-spacer>
					<v-btn :color="primaryColor" icon @click="hideDeleteAllNotifications()">
						<i class="fa fa-window-close" />
					</v-btn>
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" @click="deleteAllNotifications()">{{ $t('common.ok') }}</v-btn>
					<v-btn color="error" @click="hideDeleteAllNotifications()">{{ $t('common.cancel') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- DIALOG TO SHOW MESSAGE SENDED TO ORION -->
		<v-dialog persistent v-model="viewMessageToORION" max-width="35%">
			<v-card>
				<v-card-title class="text-h5">
					{{ $t('agentsNotifications.messageSended') }}
					<v-spacer></v-spacer>
					<v-btn :color="primaryColor" icon @click="closeMessageToORION()">
						<i class="fa fa-window-close" />
					</v-btn>
				</v-card-title>
				<div style="padding: 20px">
					<vue-json-editor
						v-model="messageToORION"
						:options="options_JsonEditor"
						:plus="false"
						height="500px"
						@error="onErrorForMessages"
					/>
				</div>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" @click="closeMessageToORION()">{{ $t('common.close') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import { getNotificationsList, deleteNotificationById } from '@/api/notifications_API';
import { dockerObjectForFilter } from '@/api/common';
import VueJsonEditor from '@/components/ui/VueJsonEditor';

export default {
	components: {
		VueJsonEditor
	},
	data() {
		return {
			search: '',
			headers: [
				{
					text: 'Container name',
					align: 'start',
					value: 'container_name'
				},
				{
					text: 'Type',
					value: 'type'
				},
				{
					text: 'Message',
					value: 'message'
				},
				{
					text: 'Creation date',
					value: 'createdAt'
				},
				{
					text: 'Actions',
					value: 'actions'
				}
			],
			list: [],
			deleteNotificationDialog: false,
			notificationToDelete: '',
			deleteAllNotificationDialog: false,
			viewMessageToORION: false,
			messageToORION: null,
			options_JsonEditor: {
				mode: 'code',
				// enableSort: true,
				enableTransform: false,
				// eslint-disable-next-line no-unused-vars
				onEditable: function (node) {
					// now the control is not editable
					return false;
				}
			},
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	computed: {},
	watch: {},
	mounted() {},
	created() {
		this.getList();
		this.commonTranslation();
	},
	methods: {
		commonTranslation() {
			this.requiredText = ' ' + this.$t('common.required');
		},

		// Lista de Notificaciones
		getList() {
			var dockerFilter = dockerObjectForFilter(this.session);
			getNotificationsList(dockerFilter).then((response) => {
				console.log('NOTIFICATIONS ->', response);
				this.list = response.message;
			});
		},

		// Mostrar cuadro de dialogo para borrar notificaciones
		showDeleteNotificationDialog(item) {
			this.deleteNotificationDialog = true;
			this.notificationToDelete = item._id;
		},

		// Ocultar modal borrar notificación
		hideDeleteNotificationDialog() {
			this.deleteNotificationDialog = false;
			this.notificationToDelete = '';
		},

		// Delete a Notification
		deleteNotification() {
			var data = {
				all: false
			};
			deleteNotificationById(this.notificationToDelete, data)
				.then((response) => {
					console.log('Respuesta. Delete a Notification');
					console.log(response);
					this.getList();
					// Notification to the UI
					this.$puiNotify.success(this.$t('agentsNotifications.deletedNotification'), this.$t('common.success'));
					this.notificationToDelete = '';
					this.deleteNotificationDialog = false;
				})
				.catch((error) => {
					// Notificación de error a la UI
					console.log(error);
					this.$puiNotify.error(error, this.$t('common.error'));
					this.notificationToDelete = '';
					this.deleteNotificationDialog = false;
				});
		},

		showDeleteAllNotifications() {
			this.deleteAllNotificationDialog = true;
		},

		hideDeleteAllNotifications() {
			this.deleteAllNotificationDialog = false;
		},

		deleteAllNotifications() {
			var data = {
				all: true
			};
			deleteNotificationById('1', data)
				.then((response) => {
					console.log('RESPONSE ALL NOTIFICATIONS');
					console.log(response);
					this.getList();
					this.$puiNotify.success(this.$t('agentsNotifications.deletedNotifications'), this.$t('common.success'));

					this.deleteAllNotificationDialog = false;
				})
				.catch((error) => {
					this.$puiNotify.error(error, this.$t('common.error'));
					this.deleteAllNotificationDialog = false;
				});
		},

		//format the date for the creationdate column
		formatDate(data) {
			var date = new Date(data).toISOString().split('T')[0];
			var time = new Date(data).toISOString().split('T')[1];
			time = time.split('.')[0];

			return date + ' ' + time;
		},

		// Controlar visibilidad modal muestra mensaje enviado a ORION
		showMessageSended(row) {
			this.messageToORION = '';

			this.messageToORION = JSON.parse(row.register);

			this.viewMessageToORION = true;
		},

		closeMessageToORION() {
			this.viewMessageToORION = false;
		},

		// Error for Json Editor (Message sended to ORION)
		onErrorForMessages() {
			console.log('error');
		}
	}
};
</script>

<style lang="postcss" scoped>
.successNotification {
	color: green;
	font-weight: bold;
}
.errorNotification {
	color: red;
	font-weight: bold;
}
.warningNotification {
	color: #b15a2e;
	font-weight: bold;
}
</style>
