import { render, staticRenderFns } from "./VueJsonEditor.vue?vue&type=template&id=2a7dcc8e&scoped=true&lang=html"
import script from "./VueJsonEditor.vue?vue&type=script&lang=js"
export * from "./VueJsonEditor.vue?vue&type=script&lang=js"
import style0 from "./VueJsonEditor.vue?vue&type=style&index=0&id=2a7dcc8e&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a7dcc8e",
  null
  
)

export default component.exports